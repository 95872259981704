import axios from "axios";
import utils from "./utils";

export const API_URL = process.env.REACT_APP_API_URL;
export const ALLOBRAIN_URL = process.env.REACT_APP_ALLOBRAIN_URL;

const jwtInstance = axios.create();

jwtInstance.interceptors.request.use((config) => {
  config.headers = {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("jwtToken"),
  };

  return config;
});

jwtInstance.interceptors.response.use(null, (error) => {
  if (error?.response?.status === 401) {
    return login().then((res) => {
      const jwtToken = res.data.token;
      localStorage.setItem("jwtToken", jwtToken);
      error.config.headers.Authorization = jwtToken;
      return axios.request(error.config);
    });
  }

  return Promise.reject(error);
});

// Login

export const login = () => {
  return axios({
    method: "post",
    url: `${API_URL}/login`,
    headers: {
      "Content-Type": "application/json",
      Authorization: utils.getToken(),
    },
  });
};

// logout

export const logout = () => {
  return axios({
    method: "post",
    url: `${API_URL}/logout`,
    headers: {
      "Content-Type": "application/json",
      Authorization: utils.getToken(),
    },
  });
};

// Dashboards

export const listDashboards = () => {
  return jwtInstance.get(`${API_URL}/dashboards`);
};

export const listSavedDashboards = (dashboardId) => {
  return jwtInstance.get(`${API_URL}/dashboards/${dashboardId}`);
};

export const loadDashboard = (
  dashboardId,
  state = "deployed",
  versionId = null
) => {
  return jwtInstance.get(
    `${API_URL}/dashboard/${dashboardId}?state=${state}&_id=${versionId}`
  );
};

export const saveDashboard = (
  dashboardId,
  dashboardSettings,
  state = "deployed"
) => {
  return jwtInstance.post(
    `${API_URL}/dashboards/${dashboardId}?state=${state}`,
    dashboardSettings
  );
};

// Modules

export const listModules = () => {
  return jwtInstance.post(`${API_URL}/get_and_save_module`, {
    type: "list",
  });
};

export const loadModule = (moduleId) => {
  return jwtInstance.post(`${API_URL}/get_and_save_module`, {
    type: "get",
    id: moduleId,
  });
};

export const saveModule = (moduleId, moduleData) => {
  return jwtInstance.post(`${API_URL}/get_and_save_module`, {
    type: "save",
    id: moduleId,
    data: moduleData,
  });
};

// Query Data

export const getQueryData = (brand, query_configs, controller) => {
  let options = {};
  if (controller) {
    options.signal = controller.signal;
  }
  return jwtInstance.post(
    `${API_URL}/get_query_data`,
    {
      brand: brand,
      query_configs: query_configs,
    },
    options
  );
};

// Chat Assistant answerer
export const getChatAssistantAnswer = (
  brand,
  conversation,
  graphs,
  parameters,
  controller
) => {
  let options = {};
  if (controller) {
    options.signal = controller.signal;
  }
  return jwtInstance.post(
    `${API_URL}/chat_assistant_answerer`,
    {
      brand: brand,
      conversation: conversation,
      graphs: graphs,
      filters: parameters,
    },
    options
  );
};

// AI filters
export const getAiFilters = (conversation, controller, brand) => {
  let options = {};
  if (controller) {
    options.signal = controller.signal;
  }
  return jwtInstance.post(
    `${API_URL}/get_ai_filters`,
    {
      brand: brand,
      conversation: conversation,
    },
    options
  );
};

// Export Data

export const exportData = (brand, query_configs, export_configs) => {
  return jwtInstance.post(`${API_URL}/export_v2`, {
    brand: brand,
    query_configs: query_configs,
    export_configs: export_configs,
  });
};

// Verbatim summary

export const getVerbatimsSummary = (
  brand,
  pipeline,
  summary_attribute,
  filters,
  language,
  abortController
) => {
  return jwtInstance.post(
    `${API_URL}/verbatims/summarize`,
    {
      brand,
      pipeline,
      summary_attribute,
      filters,
      language,
    },
    {
      signal: abortController.signal,
    }
  );
};

export const relabelisation = (brand, id, field, splitted_analysis) => {
  return axios.post(
    `${ALLOBRAIN_URL}/controlled-feedback-update`,
    JSON.stringify({
      id: id,
      brand: brand,
      base_values: {
        _id: brand + "/" + id,
      },
      field: field,
      value: splitted_analysis,
    }),
    {
      headers: {
        "Content-Type": "text/plain",
      },
    }
  );
};

// Brands

export const getBrands = () => {
  return jwtInstance.get(`${API_URL}/get_brands`);
};

// File

export const getAudioPresignedUrl = (fileId, brand) => {
  return jwtInstance.get(
    `${API_URL}/get_audio_presigned_url?id=${fileId}&brand=${brand}`
  );
};

export const deleteFile = (brand, id) => {
  return jwtInstance.post(`${API_URL}/delete_file`, {
    brand: brand,
    id: id,
  });
};

// Change State

export const changeState = (brand, id, state) => {
  return jwtInstance.post(`${API_URL}/change_state`, {
    brand: brand,
    id: id,
    state: state,
  });
};

// Send comment

export const sendComment = (brand, id, text) => {
  return jwtInstance.post(`${API_URL}/send_comments`, {
    brand: brand,
    feedback_id: id,
    text: text,
  });
};

// delete comments

export const deleteComments = (commentId, idt, brand) => {
  return jwtInstance.post(`${API_URL}/delete_comments`, {
    brand: brand,
    id: idt,
    comment_id: commentId,
  });
};

export const listDeployedQueries = () => {
  return jwtInstance.get(`${API_URL}/queries`);
};

export const getDeployedQueryInfo = (queryId) => {
  return jwtInstance.get(`${API_URL}/queries/${queryId}`);
};

export const translate = (translations) => {
  return jwtInstance.post(`${API_URL}/translate`, {
    data: [translations],
  });
};
